
import { onMounted, watch, ref } from "vue";
import { BritvicUmbracoAPIs } from "../../clients/search/src/britvicUmbracoAPIs";
import {
  ISiteSearchResult,
  ResultOptions,
  SiteSearchCriteria
} from "../../clients/search/src/models";
export default {
  props: {
    endPoint: {
      type: String,
      required: true,
      default: ""
    },
    parentid: {
      type: Number,
      required: true,
      default: -1
    },
    tags: {
      type: [],
      required: false,
      default: () => []
    },
    images: {
      type: [],
      required: false,
      default: () => []
    },
    keyword: {
      type: String,
      required: false,
      default: ""
    },
    additionalFields: {
      type: [],
      required: false,
      default: () => []
    },
    years: {
      type: [],
      required: false,
      default: () => []
    },
    page: {
      type: Number,
      required: false,
      default: 0
    },
    pagesize: {
      type: Number,
      required: false,
      default: 20
    },
    sortOrder: {
      type: String,
      required: false,
      default: ""
    },
    sortType: {
      type: String,
      required: false,
      default: "Score"
    },
    sortIsDesc: {
      type: Boolean,
      required: false,
      default: false
    },
    language: {
      type: String,
      required: false,
      default: ""
    },
    personalisationGroupIds: {
      type: String,
      required: false,
      default: ""
    }
  },
  setup(props, ctx) {
    const searchResult = ref({} as ISiteSearchResult);
    const searchLoading = ref(false);
    const searchError = ref(false);

    const fetchResources = async (resetPage = true) => {
      searchLoading.value = true;
      searchError.value = false;
      try {
        let setPage = props.page;
        if (setPage < 1 || resetPage) {
          setPage = 1;
        }

        let searchKeyword = props.keyword;
        if (searchKeyword.length < 3) {
          searchKeyword = "";
        }

        const resultOptions: ResultOptions = {
          pageNumber: setPage,
          pageSize: props.pagesize,
          sortField: props.sortOrder,
          sortDesc: props.sortIsDesc,
          sortType: props.sortType
        };

        const genericSearchRequest: SiteSearchCriteria = {
          keyword: searchKeyword,
          nodeStartId: props.parentid,
          resultOptions,
          images: props.images,
          additionalFields: props.additionalFields,
          tagFilters: [],
          language: props.language,
          personalisationGroups: props.personalisationGroupIds.split(",")
        };

        const SearchRepository = new BritvicUmbracoAPIs(props.endPoint);
        const options = {
          body: genericSearchRequest
        };

        searchResult.value = await SearchRepository.search(options);
        searchLoading.value = false;
        ctx.emit("searchChange", searchResult.value);
      } catch (e) {
        console.log(e);
        searchError.value = true;
      }
    };

    onMounted(() => {
      fetchResources();
    });

    watch(
      [
        () => props.parentid,
        () => props.keyword,
        () => props.tags,
        () => props.years,
        () => props.sortOrder,
        () => props.sortType,
        () => props.sortIsDesc
      ],
      () => {
        fetchResources();
      }
    );

    watch([() => props.page, () => props.pagesize], () => {
      fetchResources(false);
    });

    return () =>
      ctx.slots.search({
        searchError: searchError.value,
        searchResult: searchResult.value,
        searchLoading: searchLoading.value
      });
  }
};
