
import SearchBoxOverlay from "../components/SearchBoxOverlay.vue";
import SearchResults from "../components/SearchResults.vue";

import {
  keyword,
  keywordInput,
  updateKeyword,
  clearKeyword
} from "../common/searching";

import { defineComponent, ref, onMounted } from "vue";
import { QueryString } from "../models/queryString";
import { parseQueryString } from "../common/urlHelpers";
export default defineComponent({
  components: {
    SearchBoxOverlay,
    SearchResults
  },
  props: {
    searchRoot: {
      type: Number,
      required: true,
      default: -1
    },
    pageSize: {
      type: Number,
      required: true,
      default: 20
    },
    personalisationGroupIds: {
      type: String,
      required: false,
      default: ""
    },
    baseUri: {
      type: String,
      required: false,
      default: ""
    },
    language: {
      type: String,
      required: false,
      default: ""
    },
    siteLogo: {
      type: String,
      required: false,
      default: ""
    }
  },
  setup() {
    const showSearchBoxOverlay = ref(false);
    const showSearchResults = ref(false);

    onMounted(() => {
      const qsParams: QueryString = parseQueryString();
      for (const paramKey in qsParams) {
        if (paramKey === "query") {
          keyword.value = qsParams[paramKey];
          updateKeyword();
          showSearchResults.value = true;
        }
      }
    });

    const openSearchOverlay = () => {
      showSearchBoxOverlay.value = true;
    };

    const closeSearchOverlay = () => {
      clearKeyword();
      showSearchBoxOverlay.value = false;
    };

    const openSearchResults = () => {
      showSearchResults.value = true;
      showSearchBoxOverlay.value = false;
    };

    const closeSearchResults = () => {
      clearKeyword();
      showSearchResults.value = false;
    };

    return {
      openSearchOverlay,
      closeSearchOverlay,
      openSearchResults,
      closeSearchResults,
      updateKeyword,
      clearKeyword,
      showSearchBoxOverlay,
      showSearchResults,
      keyword,
      keywordInput
    };
  }
});
