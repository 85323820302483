import * as coreClient from "@azure/core-client";

export const SiteSearchCriteria: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SiteSearchCriteria",
    modelProperties: {
      nodeStartId: {
        constraints: {
          InclusiveMaximum: 2147483647,
          InclusiveMinimum: 1
        },
        serializedName: "nodeStartId",
        type: {
          name: "Number"
        }
      },
      keyword: {
        serializedName: "keyword",
        nullable: true,
        type: {
          name: "String"
        }
      },
      tagFilters: {
        serializedName: "tagFilters",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TagFilter"
            }
          }
        }
      },
      docTypes: {
        serializedName: "docTypes",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      images: {
        serializedName: "images",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      fieldFilter: {
        serializedName: "fieldFilter",
        type: {
          name: "Composite",
          className: "FieldFilter"
        }
      },
      additionalFields: {
        serializedName: "additionalFields",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      resultOptions: {
        serializedName: "resultOptions",
        type: {
          name: "Composite",
          className: "ResultOptions"
        }
      },
      language: {
        serializedName: "language",
        nullable: true,
        type: {
          name: "String"
        }
      },
      personalisationGroups: {
        serializedName: "personalisationGroups",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const TagFilter: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TagFilter",
    modelProperties: {
      tagGroupId: {
        serializedName: "tagGroupId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      tags: {
        serializedName: "tags",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const FieldFilter: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "FieldFilter",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResultOptions: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ResultOptions",
    modelProperties: {
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      sortField: {
        serializedName: "sortField",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sortDesc: {
        serializedName: "sortDesc",
        type: {
          name: "Boolean"
        }
      },
      sortType: {
        serializedName: "sortType",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ISiteSearchResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ISiteSearchResult",
    modelProperties: {
      totalResults: {
        serializedName: "totalResults",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ISiteSearchResultItem"
            }
          }
        }
      }
    }
  }
};

export const ISiteSearchResultItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ISiteSearchResultItem",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      docType: {
        serializedName: "docType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      pageTitle: {
        serializedName: "pageTitle",
        nullable: true,
        type: {
          name: "String"
        }
      },
      pageDescription: {
        serializedName: "pageDescription",
        nullable: true,
        type: {
          name: "String"
        }
      },
      url: {
        serializedName: "url",
        nullable: true,
        type: {
          name: "String"
        }
      },
      images: {
        serializedName: "images",
        nullable: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "Composite", className: "ImageItem" } }
        }
      },
      tagGroups: {
        serializedName: "tagGroups",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TagGroup"
            }
          }
        }
      },
      additionalFields: {
        serializedName: "additionalFields",
        nullable: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "String" } }
        }
      }
    }
  }
};

export const ImageItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ImageItem",
    modelProperties: {
      url: {
        serializedName: "url",
        nullable: true,
        type: {
          name: "String"
        }
      },
      altText: {
        serializedName: "altText",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TagGroup: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TagGroup",
    modelProperties: {
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        nullable: true,
        type: {
          name: "String"
        }
      },
      tags: {
        serializedName: "tags",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Tag"
            }
          }
        }
      }
    }
  }
};

export const Tag: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Tag",
    modelProperties: {
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const IndexSettingsViewModel: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IndexSettingsViewModel",
    modelProperties: {
      title: {
        serializedName: "title",
        nullable: true,
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String"
        }
      },
      taxonomy: {
        serializedName: "taxonomy",
        type: {
          name: "Composite",
          className: "Taxonomy"
        }
      },
      ctas: {
        serializedName: "ctas",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InterstitialCta"
            }
          }
        }
      },
      ctaFrequency: {
        serializedName: "ctaFrequency",
        type: {
          name: "Number"
        }
      },
      sortOrder: {
        serializedName: "sortOrder",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SortingOption"
            }
          }
        }
      },
      selectedFilters: {
        serializedName: "selectedFilters",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SelectedCtaModel"
            }
          }
        }
      },
      enableKeywordSearch: {
        serializedName: "enableKeywordSearch",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Taxonomy: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Taxonomy",
    modelProperties: {
      tagGroups: {
        serializedName: "tagGroups",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TagGroup"
            }
          }
        }
      }
    }
  }
};

export const InterstitialCta: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InterstitialCta",
    modelProperties: {
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        nullable: true,
        type: {
          name: "String"
        }
      },
      category: {
        serializedName: "category",
        nullable: true,
        type: {
          name: "String"
        }
      },
      pageName: {
        serializedName: "pageName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      customButtonLabel: {
        serializedName: "customButtonLabel",
        nullable: true,
        type: {
          name: "String"
        }
      },
      image: {
        serializedName: "image",
        nullable: true,
        type: {
          name: "String"
        }
      },
      url: {
        serializedName: "url",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SortingOption: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SortingOption",
    modelProperties: {
      key: {
        serializedName: "key",
        nullable: true,
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        nullable: true,
        type: {
          name: "String"
        }
      },
      isDescending: {
        serializedName: "isDescending",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const SelectedCtaModel: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SelectedCtaModel",
    modelProperties: {
      image: {
        serializedName: "image",
        nullable: true,
        type: {
          name: "String"
        }
      },
      tagName: {
        serializedName: "tagName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      tagId: {
        serializedName: "tagId",
        type: {
          name: "Number"
        }
      },
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "Number"
        }
      }
    }
  }
};
