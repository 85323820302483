
import { defineComponent, PropType } from "vue";
import { ISiteSearchResult } from "../clients/search/src/models";
export default defineComponent({
  props: {
    results: {
      type: Object as PropType<ISiteSearchResult>,
      required: true,
      default: {}
    }
  }
});
