
import { updateKeyword, keyword, clearKeyword } from "../common/searching";

import { defineComponent, ref } from "vue";
import { setQueryString } from "../common/urlHelpers";

export default defineComponent({
  props: {},
  setup(props, context) {
    const keywordError = ref(false);

    const keywordLengthOK = () => keyword && keyword.value.length > 2;

    const trySearch = () => {
      const keywordOK = keywordLengthOK();
      keywordError.value = !keywordOK;
      if (keywordOK) {
        const url: string = setQueryString({ query: keyword.value });
        window.history.pushState({}, "", url);
        updateKeyword();
        context.emit("searchPerformed");
      }
    };

    const closeOverlay = () => {
      context.emit("closeOverlayClick");
    };

    return {
      trySearch,
      keywordLengthOK,
      closeOverlay,
      clearKeyword,
      keyword,
      keywordError
    };
  }
});
