import {
  totalPages,
  calculateTotalPages,
  currentActivePage
} from "./pagination";
import { ref } from "vue";

const keyword = ref("" as string);
const keywordInput = ref("" as string);

const updateKeyword = (): void => {
  keywordInput.value = keyword.value;
};

const clearKeyword = (): void => {
  keywordInput.value = "";
  keyword.value = "";
};

const resourcesChanged = (update, pageSize: number): void => {
  currentActivePage.value = update.currentPage;
  const total = update.totalResults !== undefined ? update.totalResults : 0;
  totalPages.value = calculateTotalPages(total, pageSize);
};

export { resourcesChanged, clearKeyword, updateKeyword, keywordInput, keyword };
