import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "searchResultsTotal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.$t("Web.Components.ResultsIndicatorShowing")) + " ", 1),
    _createElementVNode("strong", null, _toDisplayString(_ctx.first), 1),
    _createTextVNode(" — "),
    _createElementVNode("strong", null, _toDisplayString(_ctx.last), 1),
    _createTextVNode(" " + _toDisplayString(_ctx.$t("Web.Components.ResultsIndicatorOf")) + " ", 1),
    _createElementVNode("strong", null, _toDisplayString(_ctx.total) + " " + _toDisplayString(_ctx.$t("Web.Components.ResultsIndicatorResults")), 1),
    _createTextVNode(" " + _toDisplayString(_ctx.$t("Web.Components.ResultsIndicatorFor")) + " “", 1),
    _createElementVNode("strong", null, _toDisplayString(_ctx.term), 1),
    _createTextVNode("” ")
  ]))
}